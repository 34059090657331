@import url('https://fonts.googleapis.com/css?family=Inconsolata:400,700');

.container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  border: 3px solid #000;
  height: auto;
  width: auto;
  box-sizing: border-box;
  border-radius: 5px;
  z-index: 200;

  .close {
    position: absolute;
    top: 0;
    right: 0px;
    transform: translateY(-50%) translateX(50%);
    height: 40px;
    line-height: 40px;
    width: 40px;
    border-radius: 100%;
    background-color: #4f2613;
    border: 3px solid #000;
    cursor: pointer;

    &:before {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      content: '';
      display: block;
      border: 3px solid #af4f23;
      box-sizing: border-box;
      pointer-events: none;
      background: transparent;
      border-radius: 100%;
    }
  }
  
  .inner {
    background-color: #4f2613;
    width: calc(100vw - 70px);
    max-height: 80vh;
    padding: 20px;
    border-radius: 5px;
    overflow: scroll;

    @media(min-width: 768px) {
      padding: 20px 40px;
      width: 40vw;
      min-height: 40vh;
    }
  }

  * {
    color: #fff;
    font-family: 'Inconsolata', monospace;
    text-align: center;
    font-weight: 400;
  }

  strong {
    font-weight: 700;
    text-decoration: underline;
  }
  
  &:before {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    content: '';
    display: block;
    border: 3px solid #af4f23;
    box-sizing: border-box;
    pointer-events: none;
    background: transparent;
    border-radius: 5px;
  }
}

.overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0,0,0,0.7);
  z-index: 100;
}

.container {
  h1, h2, h3 {
    width: 100%;
    display: block;
    font-size: 2rem;
    line-height: 2.4rem;
    text-align: center;
  }

  strong {
    color: #dee8b7;
  }

  h1 {
    font-weight: 700;
  }

  h2, h3 {
    margin-top: 30px;
    font-size: 1.2rem;
    line-height: 1.6rem;
    color: #dee8b7;
  }

  h3 {
    margin: 30px auto 0px;

    a {
      display: inline-block;
      color: inherit;
      width: auto;
    }
  }

  p {
    margin-top: 30px;
    font-size: 1rem;
    line-height: 1.4rem;
    color: #dee8b7;
  }

  [data-class="no-margin"] {
    margin-top: 0;

    & + [data-class="no-margin"] {
      margin-top: 5px;
    }
  }

  button {
    // color: 
    display: block;
    background: none;
    padding: 30px 0;
    margin: 20px auto;
    border: 0;
    font-size: 1.6rem;
    line-height: 2rem;
    font-weight: 700;
    cursor: pointer;
    background: rgba(255, 255, 255, 0.7);
    color: #000;
    border-radius: 5px;
    text-transform: uppercase;
    transition: 0.3s all;
    border-bottom: 3px solid #5a5a5a;
    border-left: 3px solid #5a5a5a;

    &:hover {
      background: rgba(255, 255, 255, 0.2);
      color: #fff;
      border-bottom: 3px solid #673030;
      border-left: 3px solid #673030;
    }
  }

  a {
    padding: 30px 0 10px;
    font-size: 0.9rem;
    line-height: 1.2rem;
    text-align: center;
    display: block;
    width: 100%;
  }
}