
.stage {
  position: absolute;
  background-image: url('../../../../assets/grass2.png');
  background-size: 3.336%;
  top: 0;
  left: 0;
  // border: 1px solid brown;
  box-sizing: border-box;
}

.base {
  position: absolute;
  background-color: #131315;
  background-image: url('../../../../assets/grass_bottom_middle.png');
  background-size: cover;
}

.bottom {
  composes: base;
  bottom: 0;
  background-image: url('../../../../assets/grass_bottom_middle.png');
}

.left {
  composes: base;
  left: 0;
  background-image: url('../../../../assets/grass_left.png');
}

.right {
  composes: base;
  right: 0;
  background-image: url('../../../../assets/grass_right.png');
}

.top {
  composes: base;
  top: 0;
  background-image: url('../../../../assets/grass_top_middle.png');
}


.bottomRight {
  composes: base;
  bottom: 0;
  right: 0;
  background-image: url('../../../../assets/grass_bottom_right.png');
}

.bottomLeft {
  composes: base;
  bottom: 0;
  left: 0;
  background-image: url('../../../../assets/grass_bottom_left.png');
}

.topRight {
  composes: base;
  top: 0;
  right: 0;
  background-image: url('../../../../assets/grass_top_right.png');
}

.topLeft {
  composes: base;
  top: 0;
  left: 0;
  background-image: url('../../../../assets/grass_top_left.png');
}

.stageNoBackground {
  position: absolute;
  top: 0;
  left: 0;
}