.container {
  position: fixed;
  height: 25vh;
  width: 25vh;
  // background: black;
  bottom: 0px;
  right: 0px;
  opacity: 0.8;
  z-index: 100;
}

.base {
  position: absolute;
  height: 30%;
  width: 30%;
  display: block;
  background: none;
  border: 0;
  pointer-events: auto;
  padding: 0;
  z-index: 101;

  img {
    pointer-events: none;
    display: block;
    height: 100%;
    width: 100%;
  }
}

.left {
  composes: base;
  transform: translateY(-50%) rotate(90deg);
  top: 50%;
  left: 10px;
}

.right {
  composes: base;
  transform: translateY(-50%) rotate(-90deg);
  top: 50%;
  right: 10px;
}

.top {
  composes: base;
  transform: translateX(-50%) rotate(180deg);
  left: 50%;
  top: 10px;
}

.bottom {
  composes: base;
  transform: translateX(-50%);
  left: 50%;
  bottom: 10px;
}