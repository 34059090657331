.animationSetup {
  position: fixed;
  z-index: -1;
  pointer-events: none;
  left: auto;
  top: 0;
  bottom: 0;
  right: 100%;
  // transition: 0.5s all;
  width: 100vw;
  
  &:before,
  &:after {
    content: '';
    background: #000;
    height: 50vh;
    width: 100vw;
    left: 0;
    right: 0;
    top: 100%;
    bottom: 100%;
    position: fixed;
    display: block;
  }

  &:before {
    bottom: auto;

  }

  &:after {
    top: auto;
  }
}

.animationExecution {
  composes: animationSetup;
  z-index: 500;
  pointer-events: auto;

  &:before {
    animation: sweepUp 1s linear;

  }

  &:after {
    animation: sweepDown 1s linear;
  }
}

@keyframes sweepUp {
  0% {
    top: 100%;
  }
  40% {
    top: 50%;
  }
  60% {
    top: 50%;
  }
  100% {
    top: 100%;
  }
}

@keyframes sweepDown {
  0% {
    bottom: 100%;
  }
  40% {
    bottom: 50%;
  }
  60% {
    bottom: 50%;
  }
  100% {
    bottom: 100%;
  }
}