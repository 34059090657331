.place {
  position: absolute;
  overflow: hidden;

  img {
    object-fit: cover;
    display: block;
    height: 100%;
    width: 100%;
  }
}