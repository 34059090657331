.outer {
  height: 100vh;
  width: 100vw;
  background-color: #131315;
  overflow: hidden;
}

.container {
  position: relative;
  background-color: #131315;
  margin: 0 auto;
  // overflow: hidden;

  * {
    pointer-events: none;
  }
}