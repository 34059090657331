.guy {
  position: absolute;
  background-image: url('../../../../assets/guy/stand/sd.png');
  background-size: cover;
  opacity: 1;
  // transition: 0.05s all;
  // animation: fadeIn 0.2s linear;
}

// standing (standing + direction)
.sd {
  composes: guy;
  background-image: url('../../../../assets/guy/stand/sd.png');
}

.su {
  composes: guy;
  background-image: url('../../../../assets/guy/stand/su.png');
}

.sl {
  composes: guy;
  background-image: url('../../../../assets/guy/stand/sl.png');
}

.sr {
  composes: guy;
  background-image: url('../../../../assets/guy/stand/sr.png');
}

// moving right

.mr1 {
  composes: guy;
  background-image: url('../../../../assets/guy/right/r1.png');
}

.mr2 {
  composes: guy;
  background-image: url('../../../../assets/guy/right/r2.png');
}

// moving left

.ml1 {
  composes: guy;
  background-image: url('../../../../assets/guy/left/l1.png');
}

.ml2 {
  composes: guy;
  background-image: url('../../../../assets/guy/left/l2.png');
}

// moving up

.mu1 {
  composes: guy;
  background-image: url('../../../../assets/guy/up/u1.png');
}

.mu2 {
  composes: guy;
  background-image: url('../../../../assets/guy/up/u2.png');
}

// moving down

.md1 {
  composes: guy;
  background-image: url('../../../../assets/guy/down/mf1.png');
}

.md2 {
  composes: guy;
  background-image: url('../../../../assets/guy/down/mf2.png');
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  90% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}