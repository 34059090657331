@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,700');

.container {
  margin: 0px auto;
  // background: #fff;
  border-radius: 20px;
  padding: 30px 0px 0px;
  max-width: 1000px;
  width: 98%;
  max-height: 800px;
  position: relative;
  overflow: hidden;
  box-sizing: border-box;
  margin-top: 50px;
  font-family: 'Open Sans', sans-serif;

  * {
    font-family: 'Open Sans', sans-serif;
    color: #333;
  }

  h1 {
    line-height: 2.4rem;
    font-size: 2.2rem;
  }

  h2 {
    line-height: 1.8rem;
    font-size: 1.4rem;
  }

  h3 {
    margin-top: 10px;
    line-height: 1.4rem;
    font-size: 1rem;
    font-weight: 400;
  }

  a {
    display: block;
    color: #3151b3;
    line-height: 1.4rem;
    font-size: 0.8rem;
    margin-top: 10px;

    &[data-class="inline"] {
      display: inline-block;
      width: auto;
    }
  }

  p {
    margin-top: 10px;
    line-height: 1.4rem;
    font-size: 0.8rem;
  }

  @media(min-width: 768px) {

    h1 {
      line-height: 2.4rem;
      font-size: 2.2rem;
    }
  
    h2 {
      line-height: 2rem;
      font-size: 1.6rem;
    }
  
    h3 {
      margin-top: 10px;
      line-height: 1.6rem;
      font-size: 1.2rem;
      font-weight: 400;
    }
  
    a {
      display: block;
      color: #3151b3;
      line-height: 1.2rem;
      font-size: 1rem;
      margin-top: 10px;
  
      &[data-class="inline"] {
        display: inline-block;
        width: auto;
      }
    }
  
    p {
      margin-top: 10px;
      line-height: 1.4rem;
      font-size: 1rem;
    }
  }

  @media(min-width: 768px) {
    right: 5%;
    margin-top: auto;
  }
}

.containerSmall {
  composes: container;
  background: #fff;
  margin-top: 200px;
  
  @media(min-width: 768px) {
    box-shadow: rgba(0, 0, 0, 0.64) 0 0 20px 2px;
    width: 500px;
    min-height: 300px;
    margin-top: 10%;
    left: 15%;
  }

  .inner {
    padding: 15px;
    box-sizing: border-box;
    @media(min-width: 768px) {
      padding: 20px;
    }
  }
}

.containerFull {
  composes: container;
  background: #fff;
  right: auto;
  margin-top: 0;
  
  .inner {
    padding: 10px;
    @media(min-width: 768px) {
      padding: 40px;
    }
    box-sizing: border-box;
  }
}

.inner {
  height: auto;
  width: 100%;
  overflow: hidden;
  position:relative;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.browserBar {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  border-top-right-radius: 15px;
  border-top-left-radius: 15px;
  background: #dadada;
  height: 30px;
  padding: 0 20px;
  box-sizing: border-box;
}

.barButton {
  height: 12px;
  width: 12px;
  border-radius: 100%;
  top: 50%;
  transform: translateY(-50%);
  position: absolute;
  background-color: grey;
  opacity: 0.5;
  box-sizing: border-box;

  &:after {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bototm: 0;
    border-radius: 100%;
    border: 1px solid rgba(0,0,0,0.2);
    box-sizing: border-box;
    content: '';
  }
}

.close {
  composes: barButton;
  left: 20px;
  background-color: #f15e5e;
}

.min {
  composes: barButton;
  left: calc(20px + 12px + 7px);
  background-color: #f7c531;
}

.full {
  composes: barButton;
  left: calc(20px + 12px + 7px + 12px + 7px);
  background-color: #0bca0b;
}

.menu {
  composes: barButton;
  right: 20px;
  left: auto;
  background-color: #b1b1b1;
}

.urlBar {
  height: 16px;
  position: absolute;
  width: 40%;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  background-color: rgba(255,255,255,0.6);
  border-radius: 16px;

  @media(min-width: 768px) {
    width: 60%;
  }
}